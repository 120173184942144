import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "10px",
    "@media (max-width:768px)": {
      gridTemplateColumns: "1fr 1fr",
    },
  },
})
export default function SpecificationsGrid({ columns }) {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.root}>
        {columns.map((column, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography className={classes.header} variant="h6">
              {column.heading}
            </Typography>
            <Typography className={classes.header} variant="body">
              {column.description}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}
