import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  img: {
    height: 100,
    width: 100,
    // paddingTop: '56.25%', // 16:9
  },
}))

export default function IconDescription({
  imageData,
  imageTitle,
  description,
}) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <Img className={classes.img} fluid={imageData} alt={imageTitle} />
      <CardContent>
        <Typography variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

IconDescription.propTypes = {
  imageData: PropTypes.object.isRequired,
  imageTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
}
