import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 16,
    transition: "all .25s linear",
    "&:hover, &:focus": {
      boxShadow: "-1px 1px 20px 10px rgba(0,0,0,0.1)",
    },
  },
  img: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  textContainer: {},
  pos: {
    marginTop: 12,
  },
}))

export default function CardVertical({
  imageData,
  imageTitle,
  title,
  description,
}) {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <Img className={classes.img} fluid={imageData} alt={imageTitle} />
      <CardContent className={classes.textContainer}>
        <Typography variant="h4" component="h3">
          {title}
        </Typography>
        <Typography className={classes.pos} variant="body1" component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

CardVertical.propTypes = {
  imageData: PropTypes.object.isRequired,
  imageTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
