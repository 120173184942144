import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../../layout/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import CTA from "../../components/cta"
import PageWrapper from "../../components/page-wrapper"
import CardVertical from "../../components/card-vertical"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import content from "../../locales/en/products-serogel.json"
import IconDescription from "../../components/icon-description"
import SpecificationsGrid from "../../components/specification-grid"
import Spacer from "../../components/spacer"
import Button from "../../components/button-borderless-arrow"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  bannerContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  imgBanner: {
    height: "150px",
    width: "333px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    marginTop: "36px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  gridContainer: {
    marginTop: "48px",
    marginBottom: "24px",
    "@media (max-width:768px)": {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginEnd: "32px",
  },
})

export default function Serogel({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query ProductsSerogelContent {
      heroImage: file(relativePath: { eq: "serogel/serogel_hero.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productBanner: file(
        relativePath: { eq: "serogel/serogel-banner_1@4x.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage1: file(
        relativePath: { eq: "serogel/serogel_feature_1.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage2: file(
        relativePath: { eq: "serogel/serogel_feature_2.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage3: file(
        relativePath: { eq: "serogel/serogel_feature_3.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      featureImage4: file(
        relativePath: { eq: "serogel/serogel_feature_4.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application1: file(
        relativePath: { eq: "serogel/serogel_building_insulation.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application2: file(
        relativePath: { eq: "serogel/serogel_aerospace.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application3: file(
        relativePath: { eq: "serogel/serogel_oil_and_gas_application.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application4: file(
        relativePath: { eq: "serogel/serogel_electronics_application.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application5: file(relativePath: { eq: "serogel/serogel_catalyst.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application6: file(relativePath: { eq: "derogel/derogel_clothing.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      application7: file(
        relativePath: { eq: "serogel/serogel_acoustic_coating.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      datasheet: file(relativePath: { eq: "Serogel_datasheet.pdf" }) {
        publicURL
      }
    }
  `)

  const heroImage = data.heroImage.childImageSharp.fluid
  const featureImage1 = data.featureImage1.childImageSharp.fluid
  const featureImage2 = data.featureImage2.childImageSharp.fluid
  const featureImage3 = data.featureImage3.childImageSharp.fluid
  const featureImage4 = data.featureImage4.childImageSharp.fluid
  const productBanner = data.productBanner.childImageSharp.fluid

  const applicationImage1 = data.application1.childImageSharp.fluid
  const applicationImage2 = data.application2.childImageSharp.fluid
  const applicationImage3 = data.application3.childImageSharp.fluid
  const applicationImage4 = data.application4.childImageSharp.fluid
  const applicationImage5 = data.application5.childImageSharp.fluid
  const applicationImage6 = data.application6.childImageSharp.fluid
  const applicationImage7 = data.application7.childImageSharp.fluid

  content.sectionKeyFeatures.cards[0].imageData = featureImage1
  content.sectionKeyFeatures.cards[1].imageData = featureImage2
  content.sectionKeyFeatures.cards[2].imageData = featureImage3
  content.sectionKeyFeatures.cards[3].imageData = featureImage4

  content.sectionApplications.cards[0].imageData = applicationImage1
  content.sectionApplications.cards[1].imageData = applicationImage2
  content.sectionApplications.cards[2].imageData = applicationImage3
  content.sectionApplications.cards[3].imageData = applicationImage4
  content.sectionApplications.cards[4].imageData = applicationImage5
  content.sectionApplications.cards[5].imageData = applicationImage6
  content.sectionApplications.cards[6].imageData = applicationImage7

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    new RegExp("-", "g"),
    " "
  )
  const classes = useStyles()

  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero
          imageData={heroImage}
          textTitle={content.hero.heading}
          textDescription={content.hero.description}
          dark={true}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.bannerContainer}>
              <Img className={classes.imgBanner} fluid={productBanner} />
            </div>
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionKeyFeatures.heading}
              </Typography>
            </div>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.sectionKeyFeatures.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={3}>
                  <IconDescription
                    description={card.heading}
                    imageData={card.imageData}
                    imgAlt={card.imgAlt}
                  />
                </Grid>
              ))}
            </Grid>
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionApplications.heading}
              </Typography>
            </div>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.sectionApplications.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <CardVertical
                    title={card.heading}
                    description={card.description}
                    imageData={card.imageData}
                    imgAlt={card.imgAlt}
                  />
                </Grid>
              ))}
            </Grid>
            <div className={classes.headingContainer}>
              <Typography className={classes.header} variant="h2">
                {content.sectionKeyProperties.heading}
              </Typography>
            </div>
            <div className={classes.gridContainer}>
              <SpecificationsGrid
                columns={content.sectionKeyProperties.cards}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClickUrl={data.datasheet.publicURL}
                label={"Download Data Sheet"}
              />
            </div>
          </div>
          <Spacer />
        </PageWrapper>
        <CTA />
      </Layout>
    </>
  )
}
